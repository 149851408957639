import { Collection } from "@bluebottlecoffee/design-system";
import { ShopCardProps } from "@bluebottlecoffee/design-system/dist/ShopCard/ShopCard";
import { FunctionComponent } from "react";
import { useHits } from "react-instantsearch-hooks-web";
import { AlgoliaShopCollectionProduct } from "../../types";
import { toShopCardProps } from "../../../transformers/shop-card";
import { ShopCardButton, ShopCardDetail } from "../../../sanity-schema";

type SearchResultsProps = {
  lang: string;
  region: string;
  noResultsShopCards: ShopCardProps[];
  queryString?: string;
  searchResultsCopy: string;
  searchResultsCopyDefault: string;
  searchResultsNotFoundCopy: string;
  searchResultsNotFoundSuggestionCopy: string;
};

export function isValidShopCardWithoutCollection(
  product: AlgoliaShopCollectionProduct,
): boolean {
  const { shopCard } = product;
  const button: ShopCardButton = shopCard?.button;
  const detail: ShopCardDetail = shopCard?.detail;
  return (
    !!product.name &&
    shopCard &&
    button &&
    !!button.activeText &&
    !!button.inactiveText &&
    detail &&
    !!detail.image &&
    !!detail.tagline
  );
}

export const SearchResults: FunctionComponent<SearchResultsProps> = ({
  lang,
  region,
  queryString,
  searchResultsCopy,
  searchResultsCopyDefault,
  searchResultsNotFoundCopy,
  searchResultsNotFoundSuggestionCopy,
  noResultsShopCards,
}) => {
  let collectionName: string = "";
  let shopCards: ShopCardProps[];

  const { hits } = useHits<AlgoliaShopCollectionProduct>();

  const shopCardsHits: ShopCardProps[] = hits.reduce(
    (validProducts, algoliaProduct) => {
      const valid = isValidShopCardWithoutCollection(algoliaProduct);
      if (valid) {
        validProducts.push(toShopCardProps(algoliaProduct, lang, region));
      }
      return validProducts;
    },
    [],
  );

  if (hits.length) {
    shopCards = shopCardsHits;
    collectionName = queryString
      ? `${searchResultsCopy} <b>"${queryString}"</b>`
      : searchResultsCopyDefault;
  } else {
    shopCards = noResultsShopCards;
    collectionName = queryString
      ? `${searchResultsNotFoundCopy} <b>"${queryString}"</b><br/><br/>${searchResultsNotFoundSuggestionCopy}`
      : searchResultsCopyDefault;
  }

  return <Collection name={collectionName} shopCards={shopCards} />;
};

SearchResults.defaultProps = {
  queryString: "",
};
