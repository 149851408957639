import { findBestValueVariant } from "../../algolia/etl/shared";
import { AlgoliaRecommendProduct } from "../../algolia/types";
import { Dialect } from "../../link-builders";
import { SourceProductRec } from "../../transformers/product-recs";

export const productRecsWithBestValueVariant = (
  productRecs: SourceProductRec[] | AlgoliaRecommendProduct[],
  lang: Dialect["lang"],
) =>
  productRecs.map((product) => ({
    ...product,
    bestValueVariant: findBestValueVariant(
      product.name[lang],
      product.variants,
    ),
  }));
