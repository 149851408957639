import { ChangeEvent, FunctionComponent, useCallback, useState } from "react";
import {
  Icon,
  ShopCardProps,
  SlideOverDialog,
} from "@bluebottlecoffee/design-system";
import {
  Configure,
  InstantSearch,
  SearchBox,
} from "react-instantsearch-hooks-web";
import { publicSearchClient } from "../lib/algolia/clients";
import { SearchResults } from "../lib/algolia/instant-search/components/search-results";
import { localizedProductsIndexSearchableAttributes } from "../lib/algolia/indices";

export type SearchDialogCopy = {
  inputPlaceholder: string;
  searchResultsCopy: string;
  searchResultsCopyDefault: string;
  searchResultsNotFoundCopy: string;
  searchResultsNotFoundSuggestionCopy: string;
};

type SearchDialogProps = SearchDialogCopy & {
  lang: string;
  region: string;
  noResultsShopCards: ShopCardProps[];
};

export const SearchDialog: FunctionComponent<SearchDialogProps> = ({
  inputPlaceholder,
  lang,
  region,
  searchResultsCopy,
  searchResultsCopyDefault,
  searchResultsNotFoundCopy,
  searchResultsNotFoundSuggestionCopy,
  noResultsShopCards,
}) => {
  const [hitsPerPage, setHitsPerPage] = useState<number>(6);
  const [queryString, setQueryString] = useState<string>("");
  const queryHook = (event: ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    // TODO: determine hit limit? pagination?
    setHitsPerPage(query ? 30 : 6);
    setQueryString(query);
  };

  // delay reset query so it happens when dialog is out of view
  function resetQueryString() {
    setTimeout(() => {
      setQueryString("");
    }, 500);
  }

  const searchIconBuilder = useCallback(
    () => <Icon hoverOpacity={false} name="search" />,
    [],
  );

  return (
    // onChange catches bubbled event from Algolia SearchBox, which doesn't have an onChange prop.
    // Passing queryHook prop to SearchBox doesn't seem to re-call search function as it's supposed to
    <div onChange={queryHook}>
      <InstantSearch
        indexName={`${process.env.NEXT_PUBLIC_SANITY_STUDIO_API_DATASET}-products`}
        searchClient={publicSearchClient}
      >
        <SlideOverDialog
          contentType="search"
          dialogBackgroundColor="white"
          dismissText="close"
          headContent={
            <SearchBox
              classNames={{
                root: "search-dialog-input-container",
                input: "search-dialog-input",
                submit: "search-dialog-input-submit",
              }}
              placeholder={inputPlaceholder}
              loadingIconComponent={() => null} // Algolia default renders this and it's weird
              resetIconComponent={() => null} // Algolia default renders an extra?!
              submitIconComponent={searchIconBuilder}
            />
          }
          isFullHeight={false}
          isFullWidth
          onDismiss={resetQueryString}
          showLogo
          title="Seach Dialog"
        >
          <SearchResults
            {...{
              lang,
              region,
              queryString,
              searchResultsCopy,
              searchResultsCopyDefault,
              searchResultsNotFoundCopy,
              searchResultsNotFoundSuggestionCopy,
              noResultsShopCards,
            }}
          />
          <Configure
            hitsPerPage={hitsPerPage}
            distinct
            filters="forSale:true"
            restrictSearchableAttributes={localizedProductsIndexSearchableAttributes(
              lang,
            )}
          />
        </SlideOverDialog>
      </InstantSearch>
    </div>
  );
};
