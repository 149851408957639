import { ShopCardProps } from "@bluebottlecoffee/design-system";
import { AlgoliaShopCollectionProduct } from "../algolia/types";
import { productPage } from "../link-builders";
import {
  ImageResponsive,
  ShopCardButton,
  ShopCardDetail,
} from "../sanity-schema";
import { ShopCardWithSlug } from "../sanity/shop-card-queries";
import { ImageType, toImageProps } from "./image";
import { toVariantProps } from "./variant";

export function isValidShopCard(
  product: AlgoliaShopCollectionProduct,
): boolean {
  const { shopCard } = product;
  const button: ShopCardButton = shopCard?.button;
  const detail: ShopCardDetail = shopCard?.detail;
  return (
    !!product.name &&
    !!product.primaryShopCollectionName &&
    shopCard &&
    button &&
    !!button.activeText &&
    !!button.inactiveText &&
    detail &&
    !!detail.image &&
    !!detail.tagline
  );
}

export type ShopCardPropsWithSlug = ShopCardProps & {
  productSlug: ShopCardWithSlug["productSlug"];
};

export function toShopCardProps(
  data: AlgoliaShopCollectionProduct,
  lang: string,
  region: string,
): ShopCardPropsWithSlug {
  const productPlaceholderImage = "/coffee-bag_whrevt.png";
  const circleDetailPlaceholderImage =
    "/ar_1:1,b_rgb:ffffff,bo_0px_solid_rgb:ffffff,c_crop,fl_clip,g_center,h_1000,r_1000,w_1000/v1630525715/roasted-beans_wfou4v.png";
  const inactive = !data.forSale;
  const productSlug = data.shopCard?.productSlug;
  const product = data.shopCard?.product;
  const detail = data.shopCard?.detail;
  const button = data.shopCard?.button;
  const imageOverlayTextTheme = data.shopCard?.hoverTextColor ?? null;
  const defaultProductImage: ImageResponsive | undefined =
    (data.images?.length ?? 0) > 0 ? data.images[0] : undefined;
  const productImage: ImageType | undefined =
    product?.image ?? defaultProductImage;
  const detailImage: ImageType | undefined = detail?.image;

  try {
    const productImageSource: string = productImage
      ? toImageProps(productImage, lang).src
      : productPlaceholderImage;
    const detailImageSource: string = detailImage
      ? toImageProps(detailImage, lang).src
      : circleDetailPlaceholderImage;

    return {
      productSlug, // This is used for analytics  to track when a product is clicked
      name: data.name[lang],
      collectionName: data.primaryShopCollectionName?.[lang] ?? "",
      link: productPage({ region, lang, slug: productSlug }),
      cta: {
        activeText: button.activeText[lang],
        inactiveText: button.inactiveText[lang],
        inactive,
      },
      product: {
        bannerText: data.shopCard?.product?.banner?.text?.[lang] ?? null,
        image: {
          src: productImageSource,
          altText: product?.image?.altText?.[lang] || "",
        },
      },
      detail: {
        isCircleCrop: !!detail.isCircleCrop,
        tagline: detail?.tagline[lang] ?? null,
        infoLine1: detail?.infoLine1?.[lang] ?? null,
        infoLine2: detail?.infoLine2?.[lang] ?? null,
        image: {
          src: detailImageSource,
          altText: detail?.image?.altText?.[lang] ?? "",
        },
      },
      inactive,
      imageOverlayTextTheme,
      bestValueVariant: toVariantProps(data.bestValueVariant, lang),
    };
  } catch (e) {
    throw new Error(
      `Product "${data.name[lang]}" threw: ${e}. Make any updates and then re-index Algolia for edits to appear.`,
    );
  }
}

/**
 * Assigns `onClick` callback to the ShopCardCTA on the provided `shopCard`
 *
 * @param shopCard ShopCardProps on which to assign an `onClick` callback to the ShopCardCTA
 * @param onClick Callback
 *
 * @returns ShopCardProps with `onClick` callback
 */
export function assignOnClick(
  shopCard: ShopCardPropsWithSlug,
  onClick: () => any,
) {
  // eslint-disable-next-line no-param-reassign
  shopCard.onClick = onClick;
  return shopCard;
}
