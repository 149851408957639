import { AlgoliaRecommendProduct } from "../algolia/types";
import {
  OptionType,
  Product,
  ProductSubscriptionOption,
  QuantityLabel,
  ShopCollection,
  Subscription,
  SubscriptionInterval,
  Variant,
} from "../sanity-schema";
import { Dialect } from "../link-builders";
import {
  assignOnClick,
  ShopCardPropsWithSlug,
  toShopCardProps,
} from "./shop-card";
import { DereferencedShopCard } from "../sanity/shop-card-queries";
import { DereferencedVariant } from "../sanity/variant-queries";

export type SourceProductRecSubscription = Omit<Subscription, "intervals"> & {
  intervals: SubscriptionInterval;
};

export type SourceProductRecSubscriptionOption = Omit<
  ProductSubscriptionOption,
  "item" | "quantityLabel"
> & {
  item: Variant;
  quantityLabel: QuantityLabel;
  showSubcriptionPriceDisclaimer: boolean;
};

export type SourceProductRec = Omit<
  Product,
  | "optionTypes"
  | "variants"
  | "shopCollections"
  | "subscription"
  | "subscriptionOptions"
  | "shopCard"
> & {
  optionTypes: OptionType[];
  shopCollections: ShopCollection[];
  subscription: SourceProductRecSubscription;
  subscriptionOptions: SourceProductRecSubscriptionOption[];
  shopCard?: DereferencedShopCard;
  variants: DereferencedVariant[];
  bestValueVariant: DereferencedVariant;
};

export const transformProductRecsToShopCards = (
  productRecs: SourceProductRec[] | AlgoliaRecommendProduct[],
  lang: Dialect["lang"],
  region: Dialect["region"],
  trackClickProduct: (slug: string) => any,
): ShopCardPropsWithSlug[] =>
  productRecs.map((productRec) => {
    const shopCard = toShopCardProps(productRec, lang, region);

    return assignOnClick(shopCard, () =>
      trackClickProduct(shopCard.productSlug),
    );
  });
